<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <table-box ref="tableBox" tableTitle="抽奖管理" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #title-right>
          <div class="search-div">
            <label>
              <select v-model="searchType">
                <option value="1">名称</option>
              </select>
            </label>
            <label>
              <input class="search-input" type="text" maxlength="20" v-model="searchMsg" placeholder="搜索相关数据..." />
            </label>
            <input class="search-btn" type="button" value="搜索" @click="onSearch" />
          </div>
        </template>
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th v-if="isOperation" class="operation-th">操作</th>
        </template>
        <template v-if="$validateAuthority(2700000,2713000,2713001)" #tableSubHead>
          <th class="table-sub-head-th" :colspan="tableFieldNum">
            <div class="div-btn cursor-el append-btn" @click="onShowEditAward">
              <img src="../../assets/add-data.png" height="14" width="14" alt="" />
              <label class="cursor-el">奖品设置</label>
            </div>
          </th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td v-if="isOperation">
              <div class="operation-btn-div">
                <span v-if="$validateAuthority(2700000,2713000,2713002) && props.item.state === 1" class="cursor-el" @click="onDeliverGoods(props.item.id)">处理</span>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../../components/TableBox";
import AwardEditLayer from "../layer/AwardEditLayer";
import { getUserAwardItems, postAwardDeliver } from "../../common/api";

export default {
  name: "Awards",
  components: { TableBox },
  data() {
    return {
      action: getUserAwardItems,
      reloadData: false,
      searchType: "1",
      searchMsg: "",
      queryParams: {
        type: 0,
        msg: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "编号", name: "id", title: "id", isHtml: false, value: this.getObjValue },
        { state: true, alias: "奖品名称", name: "awardName", title: "awardName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "用户账号", name: "account", title: "account", isHtml: false, value: this.getObjValue },
        { state: true, alias: "用户昵称", name: "nickname", title: "nickname", isHtml: false, value: this.getObjValue },
        { state: true, alias: "收件人", name: "addressee", title: "addressee", isHtml: false, value: this.getObjValue },
        { state: true, alias: "联系电话", name: "phone", title: "phone", isHtml: false, value: this.getObjValue },
        { state: true, alias: "收货地址", name: "address", title: "address", isHtml: false, value: this.getObjValue },
        { state: true, alias: "状态", name: "state", title: "state", isHtml: false, value: this.getObjValue },
        { state: true, alias: "备注", name: "remarks", title: "remarks", isHtml: false, value: this.getObjValue }
      ]
    };
  },
  computed: {
    isOperation() {
      return this.$hasAuthority(2700000,2713000,[2713002]);
    },
    tableFieldNum() {
      let num = this.isOperation ? 1 : 0;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  methods: {
    getObjValue(item, field) {
      if(field === "state"){
        return item[field] === 0 ? "未中将" : (item[field] === 1 ? "待发货" : "已发货");
      }else{
        return item[field];
      }
    },
    onReloadData() {
      this.reloadData = true;
    },
    onSearch() {
      this.queryParams.type = this.searchType;
      this.queryParams.msg = this.searchMsg.trim();
    },
    onShowEditAward() {
      this.$layer.iframe({
        content: {
          content: AwardEditLayer,
          parent: this
        },
        area: ["800px", "620px"],
        title: "奖品设置"
      });
    },
    onDeliverGoods(id) {
      let _this = this;
      this.$layer.prompt(
          {
            formType: 3,
            maxlength: 200,
            area: ["500px", "300px"],
            title: "发货备注"
          },
          function(value, index){
            postAwardDeliver({
              id: id,
              remarks: value
            })
              .then(() => {
                _this.onReloadData();
                _this.$layer.msg("操作成功");
                _this.$layer.close(index);
              })
              .catch(error => {
                _this.$layer.close(index);
                if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                  _this.$layer.msg(error.data.msg);
                } else {
                  _this.$layer.msg("操作失败");
                }
              });
          }
      );
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
    .search-div {
      height: 100%;
      display: flex;
      select {
        width: 110px;
        height: 100%;
        border: 1px solid #dcdcdc;
      }
      .search-input {
        width: 140px;
        height: 33px;
        border: 1px solid #dcdcdc;
        padding: 0 15px;
        margin-left: 5px;
      }
      .search-btn {
        width: 80px;
        height: 100%;
        color: #f4f6f5;
        border: none;
        background: #4676c8;
      }
    }
    .operation-th {
      width: 80px;
    }
    .table-sub-head-th {
      .div-btn {
        float: left;
        width: 88px;
        height: 23px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .append-btn {
        margin-left: 15px;
        margin-right: 10px;
      }
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>