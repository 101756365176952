<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <template v-for="(item, index) in awardData">
        <div class="award-div">
          <label class="upload-img-label" @change="onAddImg($event, item)">
            <img class="cursor-el" :src="getAwardItemIcon(item)" alt="" />
            <input class="cursor-el" type="file" :disabled="item.mark !== 1 || !item.isDel" />
          </label>
          <label><input type="text" v-model="item.name" :disabled="item.mark !== 1 || !item.isDel" maxlength="4" placeholder="请输入奖品名称"></label>
          <div class="odds-div">
            <label><input type="number" v-model="item.odds" :disabled="item.mark !== 1" maxlength="4" placeholder="中将概率"></label>
            <label>
              <select v-model="item.range" :disabled="item.mark !== 1">
                <option value="10">十分比</option>
                <option value="100">百分比</option>
                <option value="1000">千分比</option>
                <option value="10000">万分比</option>
              </select>
            </label>
          </div>
          <div class="operation-btn-div">
            <input type="button" value="删除" :disabled="item.mark !== 1" @click="onDel(item)">
            <input type="button" value="保存" @click="onSave(item)">
          </div>
          <label class="mark-label"><input type="checkbox" :checked="item.mark === 1" @change="onChecked(item)"></label>
        </div>
        <div class="award-div" v-if="index === 3"></div>
      </template>
    </div>
  </div>
</template>

<script>
import { getAwardItems, postAwardEdit } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      awardData: [
        {id: "", name: "", icon: "", odds: "", range: 100, mark: 0, isDel: false, img: null},
        {id: "", name: "", icon: "", odds: "", range: 100, mark: 0, isDel: false, img: null},
        {id: "", name: "", icon: "", odds: "", range: 100, mark: 0, isDel: false, img: null},
        {id: "", name: "", icon: "", odds: "", range: 100, mark: 0, isDel: false, img: null},
        {id: "", name: "", icon: "", odds: "", range: 100, mark: 0, isDel: false, img: null},
        {id: "", name: "", icon: "", odds: "", range: 100, mark: 0, isDel: false, img: null},
        {id: "", name: "", icon: "", odds: "", range: 100, mark: 0, isDel: false, img: null},
        {id: "", name: "", icon: "", odds: "", range: 100, mark: 0, isDel: false, img: null}
      ],
      tipMsg: ""
    }
  },
  created() {
    this.initData();
  },
  methods: {
    getIndex(index) {
      switch (index) {
        case 0: return 0;
        case 1: return 1;
        case 2: return 2;
        case 3: return 4;
        case 4: return 7;
        case 5: return 6;
        case 6: return 5;
        case 7: return 3;
        default: return -1;
      }
    },
    initData() {
      getAwardItems()
        .then(data => {
          data.forEach(item => {
            let index = this.getIndex(item.index);
            let award = this.awardData[index];
            award.id = item.id;
            award.name = item.name;
            award.icon = Object.prototype.hasOwnProperty.call(award, "icon") ? item.icon : "";
            award.odds = item.odds;
            award.range = item.range;
            award.mark = item.mark;
            award.img = null;
          });
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询奖品信息失败，请稍后重试");
          }
          this.$layer.close(this.layerid);
        });
    },
    getAwardItemIcon(item) {
      if(item.mark !== 1 || item.icon.length < 1){
        return require("../../assets/picture.png");
      }else{
        return item.img == null ? `${this.$imgBaseURL}/${item.icon}` : item.icon;
      }
    },
    onChecked(award) {
      award.isDel = true;
      if(award.mark === 1){
        award.name = "";
        award.icon = "";
        award.img = null;
        award.odds = "";
        award.range = "100";
        award.mark = 2;
      }else{
        award.name = "";
        award.icon = "";
        award.img = null;
        award.odds = "50";
        award.range = 100;
        award.mark = 1;
      }
    },
    onAddImg(event, item) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let that = this;
      let img = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        item.icon = reader.result;
        item.img = img;
        event.target.value = null;
      };
      reader.onerror = function() {
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onDel(award) {
      award.isDel = true;
      award.name = "";
      award.icon = "";
      award.img = null;
      award.odds = "50";
      award.range = 100;
    },
    onSave(award) {
      let formData = new FormData();
      formData.append("id", award.id);

      if(award.isDel){
        formData.append("state", 1);
        formData.append("mark", award.mark);
        if(award.mark === 1){
          if(award.name.length < 1){
            this.$layer.msg("请输入奖品名称");
            return;
          }
          if(award.img == null){
            this.$layer.msg("请上传奖品图片");
            return;
          }
          formData.append("name", award.name);
          formData.append("file", award.img);
          formData.append("odds", award.odds)
          formData.append("range", award.range)
        }
      }else{
        formData.append("state", 2);
        formData.append("odds", award.odds)
        formData.append("range", award.range)
      }

      postAwardEdit(formData)
        .then(() => {
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 530px;
    height: 530px;
    margin: 20px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .award-div {
      width: calc(100% / 3 - 10px);
      height: calc(100% / 3 - 10px);
      border: 1px solid #787878;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .upload-img-label {
        margin-top: 3px;
      }
      input[type="text"] {
        width: 80px;
        height: 22px;
        margin-top: 10px;
      }
      .odds-div {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        input[type="number"] {
          width: 70px;
          height: 22px;
        }
        select {
          height: 26px;
          margin-left: 5px;
        }
      }
      .operation-btn-div {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        input[type="button"] {
          width: 60px;
          height: 25px;

        }
        input[type="button"]:first-child {
          margin-right: 5px;
        }
      }
      .mark-label {
        position: absolute;
        top: 3px;
        right: 5px;
        input[type="checkbox"] {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>